import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { useContext, useState } from 'react';
import { palestinian_monthly_data, israeli_monthly_data} from './context';

export const ExchangeRate: React.FC = () => {
  const palestinian_monthly = useContext(palestinian_monthly_data);
  const israeli_monthly = useContext(israeli_monthly_data);
  const current_index = palestinian_monthly.length
  const length = Object.keys(palestinian_monthly).length


  const [currentIndex, setCurrentIndex] = useState(length - 1);
  

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };


  const chartOptions: Options = {
    chart: {
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: 'rgba(0,0,0,0)',
        reflow:true,
        marginTop:0,
        spacingTop:0
    },
    credits: {
      enabled: false
    },
    title: {
        text: 'Monthly <br />Exchange Rate <br />' + Object.keys(palestinian_monthly)[currentIndex],
        align: 'center',
        verticalAlign: 'middle',
        y: 160,
        style: {
            color: 'white'
        }
    },
    tooltip: {
        pointFormat: '<b style="color:red">' + palestinian_monthly[Object.keys(palestinian_monthly)[currentIndex]]  + '</b> Palestinians Died <br /><b style="color:orange">' + israeli_monthly[Object.keys(israeli_monthly)[currentIndex]] + '</b> Israeli Died'
    },
    accessibility: {
        point: {
            valueSuffix: ''
        }
    },
    plotOptions: {
        pie: {
            dataLabels: {
                enabled: true,
                distance: -50,
                style: {
                    fontWeight: 'bold',
                    color: 'white'
                }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '100%'],
            size: '130%'
        }
    },
    series: [{
        type: 'pie',
        name: 'Exchange Rate',
        innerSize: '50%',
        data: [
            ['Palestinian', (palestinian_monthly[Object.keys(palestinian_monthly)[currentIndex]] / 
                        (palestinian_monthly[Object.keys(palestinian_monthly)[currentIndex]] + israeli_monthly[Object.keys(israeli_monthly)[currentIndex]]))*100],
            ['Israeli', (israeli_monthly[Object.keys(palestinian_monthly)[currentIndex]] / 
                        (palestinian_monthly[Object.keys(palestinian_monthly)[currentIndex]] + israeli_monthly[Object.keys(israeli_monthly)[currentIndex]]))*100],
        ]
    }]

  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
      <div className="flexbox-container" >
        <img src={require('../src/assets/images/palestine-32.png')} style={{marginRight:'5em'}}></img>
        <button onClick={handlePrevClick} disabled={currentIndex == 0} className='space'>Previous</button>
        <button onClick={handleNextClick} disabled={currentIndex >= length -1 } className='space'>Next</button>
        <img src={require('../src/assets/images/israel-32.png')} style={{marginLeft:'5em'}}></img>
      </div>
    </>
  );

}
