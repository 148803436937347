import { createContext } from 'react';

const palestinian_monthly: Record<string, number> = {
	'01-2020':3,
	'02-2020':7,
	'03-2020':3,
	'04-2020':1,
	'05-2020':4,
	'06-2020':1,
	'07-2020':1,
	'08-2020':2,
	'09-2020':0,
	'10-2020':2,
	'11-2020':2,
	'12-2020':2,
	'01-2021':3,
	'02-2021':1,
	'03-2021':1,
	'04-2021':1,
	'05-2021':266,
	'06-2021':7,
	'07-2021':5,
	'08-2021':10,
	'09-2021':11,
	'10-2021':1,
	'11-2021':3,
	'12-2021':7,
	'01-2022':4,
	'02-2022':6,
	'03-2022':13,
	'04-2022':22,
	'05-2022':8,
	'06-2022':13,
	'07-2022':6,
	'08-2022':42,
	'09-2022':19,
	'10-2022':24,
	'11-2022':19,
	'12-2022':11,
	'01-2023':35,
	'02-2023':27,
	'03-2023':24,
	'04-2023':10,
	'05-2023':51,
	'06-2023':20,
	'07-2023':25,
	'08-2023':18,
	'09-2023':13,
	'10-2023':8728,
	'11-2023':7000,
	'12-2023':5210,
	'01-2024':6000,
	'02-2024':7200,
};
const israeli_monthly: Record<string, number> = {
	'01-2020':0,
	'02-2020':0,
	'03-2020':0,
	'04-2020':0,
	'05-2020':0,
	'06-2020':0,
	'07-2020':0,
	'08-2020':1,
	'09-2020':0,
	'10-2020':0,
	'11-2020':0,
	'12-2020':1,
	'01-2021':0,
	'02-2021':0,
	'03-2021':0,
	'04-2021':0,
	'05-2021':7,
	'06-2021':0,
	'07-2021':0,
	'08-2021':0,
	'09-2021':0,
	'10-2021':0,
	'11-2021':0,
	'12-2021':1,
	'01-2022':0,
	'02-2022':0,
	'03-2022':2,
	'04-2022':4,
	'05-2022':3,
	'06-2022':0,
	'07-2022':0,
	'08-2022':0,
	'09-2022':1,
	'10-2022':2,
	'11-2022':5,
	'12-2022':0,
	'01-2023':6,
	'02-2023':6,
	'03-2023':1,
	'04-2023':3,
	'05-2023':2,
	'06-2023':4,
	'07-2023':0,
	'08-2023':4,
	'09-2023':0,
	'10-2023':1200,
	'11-2023':50,
	'12-2023':5,
	'01-2024':1,
	'02-2024':1,
};

const palestinian_yearly: Record<string, number> = {
	'2000':6,
	'2001':189,
	'2002':1031,
	'2003':586,
	'2004':829,
	'2005':190,
	'2006':665,
	'2007':385,
	'2008':884,
	'2009':1036,
	'2010':82,
	'2011':117,
	'2012':256,
	'2013':38,
	'2014':2268,
	'2015':157,
	'2016':107,
	'2017':69,
	'2018':295,
	'2019':136,
	'2020':28,
	'2021':316,
	'2022':187,
	'2023':223+8728+7000+5210,
	'2024':6000+7200,
};
const israeli_yearly: Record<string, number> = {
	'2000':29,
	'2001':161,
	'2002':291,
	'2003':146,
	'2004':99,
	'2005':44,
	'2006':19,
	'2007':10,
	'2008':29,
	'2009':9,
	'2010':7,
	'2011':12,
	'2012':5,
	'2013':3,
	'2014':59,
	'2015':20,
	'2016':8,
	'2017':7,
	'2018':7,
	'2019':9,
	'2020':2,
	'2021':9,
	'2022':17,
	'2023':26+1500+50+5,
	'2024':1+1,
};

export const palestinian_monthly_data = createContext<Record<string, number>>(palestinian_monthly);
export const israeli_monthly_data = createContext<Record<string, number>>(israeli_monthly);
export const palestinian_yearly_data = createContext<Record<string, number>>(palestinian_yearly);
export const israeli_yearly_data = createContext<Record<string, number>>(israeli_yearly);
