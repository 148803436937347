import * as React from "react";
import { render } from "react-dom";
import { Yearly } from "../src/Yearly";
import "../src/assets/css/main.css";
import { ExchangeRate } from "./ExchangeRate";
import { useContext, useState } from 'react';
import { palestinian_monthly_data, israeli_monthly_data} from './context';
import { reduce } from "highcharts";

function App(): JSX.Element {
  const palestinian_monthly = useContext(palestinian_monthly_data);
  const israeli_monthly = useContext(israeli_monthly_data);
  const length = Object.keys(palestinian_monthly).length;
  const palestinian_deaths = palestinian_monthly[Object.keys(palestinian_monthly)[length - 1]];
  const israeli_deaths = israeli_monthly[Object.keys(israeli_monthly)[length - 1]];
  const xchange_rate = ((palestinian_deaths) / israeli_deaths).toFixed(2);

  return (
    <div >
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop:'-3em'}}>
        <header id="header">
          <h2 style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>Palestinian vs Israeli lives exchange rate</h2>
          <p>Data to help explore what is a <b>proportional</b> response to the inexcuseable violence of Hamas against Israeli citizens</p>
          <div className="row" style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop:'-0.5em'}}>
            <div className="column">Current Rate:<br /> <b style={{color:'orange'}}>1</b> Israeli life is worth <b style={{color:'red'}}>{xchange_rate}</b> Palestinian lives ...</div>
            <div className="column">
              <div >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b style={{color:'red'}}>{palestinian_deaths} </b>&nbsp; Palestinians dead</div>
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}><b style={{color:'orange'}}>{israeli_deaths} </b>&nbsp; Israelis dead</div>
            </div>
          </div>
        </header>
      </div>

      <div  style={{marginTop:'-5em'}}>
        <ExchangeRate />
      </div>
      <div style={{marginTop:'1em'}}></div>
      <hr />
      <Yearly />
			<div id="signup-form" >
					<div style={{fontSize:'0.5em'}}>Recent data not validated. <b>Data sourced</b> from <a href='https://www.btselem.org/'>B'Tselem</a>, an Israeli information center for human rights. <b>Questions, Comments?</b> what.is.proportional.response@gmail.com</div>
			</div>

    </div>
  );

}

const rootElement = document.getElementById("root");
render(<App />, rootElement);
