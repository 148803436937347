import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { useContext } from 'react';
import { palestinian_yearly_data, israeli_yearly_data} from './context';

export const Yearly: React.FC = () => {
  enum Nationality {
    Palestinian = 1,
    Israeli,
  }
  const palestinian_yearly = useContext(palestinian_yearly_data);
  const israeli_yearly = useContext(israeli_yearly_data);
  const getData = (nationality: Nationality, years: string[]) => {
    let out = new Array<number>(years.length, 0);
    for (let index = 0; index < years.length; index++) {
      const element = years[index];
      if ( nationality === Nationality.Palestinian ) {
        out[index] = palestinian_yearly[years[index]];
      } 
      else if ( nationality === Nationality.Israeli ) {
        out[index] = israeli_yearly[years[index]];
      } 
    }

    return out;
  }

  const categories = ['2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010'];
  const chartOptions: Options = {
      chart: {
        type: 'bar',
        backgroundColor: 'rgba(0,0,0,0)',
        reflow:true,
        height: 500,
        marginTop:0,
        spacingTop:0
      },
      credits: {
        enabled: false
      },
    title: {
        text: 'Palestinian and Israeli Yearly Death Ratios',
        align: 'center',
        verticalAlign: 'bottom',
        style: {
            color: 'white'
        }
    },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true
          }
        }
      },
      xAxis: [{
        categories: categories,
        labels: {
          enabled: true,
        },
        left: '51%',
        lineWidth: 0
      }],
      yAxis: [{
        width: '48%',
        min: 0,
        max: 6000,
        reversed: true,
        offset: 0,
        title: {
          text: null
        }
      }, {
        width: '48%',
        min: 0,
        max: 6000,
        left: '52%',
        offset: 0,
        title: {
          text: null
        }
      }],
      series: [{
        data: getData(Nationality.Palestinian, categories),
        type: 'bar',
        yAxis: 0,
        name: 'Palestinian Deaths'
      }, {
        data: getData(Nationality.Israeli, categories),
        type: 'bar',
        yAxis: 1,
        name: 'Israeli Deaths'
      }]
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </>
  );

}
